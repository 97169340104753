import React from "react";
import "./Arrow.css";

export default function Arrow(props) {
  return (
    <>
      <h2 className="arrow"> {props.tittle}</h2>
    </>
  );
}
